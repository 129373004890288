<template>
    <div class="body">
        <van-nav-bar title="导入日报审核" left-text="返回" @click-left="back" fixed left-arrow/>
        <div style="height: 46px"></div>
        <div class="lis" v-for="item in list" :key="item.id">
            <div class="lis_til">
                <p>
                    <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText></span>
                    <span v-else>{{item.name}}</span>
                    <span style="margin-left:30px;">{{item.date}}</span>
                </p>
                <p style="color: #C03131">待审核</p>
            </div>
            <div class="lis_con">
                <div>项目： {{item.project}}</div>
                <div v-if="item.subProjectName">子项目： {{item.subProjectName}}</div>
                <div>工作时长： {{item.time}}h</div>
            </div>
            <div class="lis_boot">
                <div style="background: #1989FA" @click="approve(item, item.id)">通过</div>
                <div style="background: #C03131" @click="showDenyDialog(item)">驳回</div>
            </div>
        </div>
        <van-popup v-model="denyReasonDialog" position="bottom" closeable >
            <van-cell>请输入原因</van-cell>
            <van-field class="form_input"
                v-model="denyForm.reason" name="reason" type="textarea" :placeholder="'请输入您决定'+(denyForm.i==0?'驳回':'撤销')+'的原因'"
                rows="3" autosize  />
            <van-button style="width:100%;" type="info" @click="deny()">提交</van-button>
        </van-popup>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: JSON.parse(localStorage.userInfo),
            search: {
                date: null,
                projectId: null,
                pageIndex:1,
            },
            list: [],
            denyReasonDialog: false,
            denyForm: {
                id: null,
                i: null,
                date: null,
                reportIds: '',
                reason: ''
            }
        }
    },
    created() {},
    mounted() {
        this.obtain()
    },
    methods: {
        // 返回
        back() {
            history.back();
        },
        // 获取日报
        obtain() {
            const toast = this.$toast.loading({
                forbidClick: true,
                duration: 0
            });
            this.$axios.post('/report/listImportByState', this.search)
            .then(res => {
                this.$toast.clear();
                this.list = res.data.record;
            }).catch(err=> {
                this.$toast.clear();
                alert('err=' + err);
            });
        },
        // 通过日报
        approve(item, id) {
            const toast = this.$toast.loading({
                forbidClick: true,
                duration: 0
            });
            var ids = id;
            
            this.$axios.get('/report/approve', {params:{id: id, reportIds: ids}})
            .then(res => {
                this.obtain()
                this.$toast.clear();
                this.$toast.success('审核成功');
            }).catch(err=> {
                this.$toast.clear();
                alert('err=' + err);
            });
        },
        // 驳回
        showDenyDialog(item) {
            this.denyReasonDialog = true
            var ids = item.id;
            this.denyForm = {id: item.id ,i:0, date: item.date, reportIds: ids, reason:null};
        },
        // 驳回提交
        deny() {
            const toast = this.$toast.loading({
                forbidClick: true,
                duration: 0 
            });
            this.$axios.post('/report/deny', this.denyForm)
            .then(res => {
                this.obtain()
                this.$toast.clear();
                this.$toast.success('提交成功');
                this.denyReasonDialog = false
            }).catch(err=> {
                this.$toast.clear();
                alert('err=' + err);
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .lis {
        background: #fff;
        padding: 10px;
        box-sizing: border-box;
        color: #333333;
        margin-bottom: 30px;
        font-size: 14px;
        .lis_til {
            display: flex;
            justify-content: space-between;
            padding: 0 6px 10px 6px;
            border-bottom: 1px solid #ebedf0;
        }
        .lis_con {
            padding: 0 6px;
            border-bottom: 1px solid #ebedf0;
            div {
                line-height: 30px;
            }
        }
        .lis_boot {
            padding: 10px 6px 0 6px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            div {
                width: 40px;
                line-height: 28px;
                text-align: center;
                margin-left: 20px;
                color: #fff;
                font-size: 12px;
                border-radius: 2px;
            }
        }
    }
</style>
